import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Content from "./components/Content/Content";

import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyCDBO1NWURhFlBZpd1Bxc2pxUPhV8k8LMQ",
  authDomain: "hey-addy-chatgpt.firebaseapp.com",
  databaseURL: "https://hey-addy-chatgpt-default-rtdb.firebaseio.com",
  projectId: "hey-addy-chatgpt",
  storageBucket: "hey-addy-chatgpt.appspot.com",
  messagingSenderId: "284266859441",
  appId: "1:284266859441:web:68d1fc5acb5e9ffa410b90",
  measurementId: "G-J966MHS7YM"
};
initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);
// getAnalytics(app);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Sidebar />
        <Content />
      </BrowserRouter>
    </div>
  );
}

export default App;
